import React from "react";
import SectionsMap from "./SectionBuilder.model";

const SectionBuilderController = ({
  sections,
  PageBuilderMap = {},
  hasSectionIdVisibility = false,
}) => {
  return (
    <>
      {sections?.map((section) => {
        const { id, __component, ...props } = section;
        let sectionName = __component?.split("section.")[1] || "";
        if (sectionName.length == 0) {
          sectionName = __component?.split("v2.")[1] || "";
        }

        const Component =
          PageBuilderMap[sectionName] || SectionsMap[`${sectionName}`];

        if (Component && (!hasSectionIdVisibility || section?.section_id)) {
          return (
            <Component
              key={section?.section_id}
              id={section?.section_id}
              {...props}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default SectionBuilderController;
