import React from "react";
import SectionsMap from "./PageBuilder.model";
import dynamic from "next/dynamic";
import SectionBuilderController from "./SectionBuilder.controller";
const Footer = dynamic(() => import("../Footer/Footer.controller"));

const PageBuilder = ({ sections, showFooter = true, spaceBetweenSections }) => {
  if (sections?.length < 1) {
    return <>{showFooter !== false ? <Footer /> : null}</>;
  }
  return (
    <div
      className="sections-wrapper"
      style={
        spaceBetweenSections
          ? { "--section-gap": `${spaceBetweenSections}px` }
          : null
      }
    >
      <SectionBuilderController
        sections={sections}
        PageBuilderMap={SectionsMap}
        spaceBetweenSections={spaceBetweenSections}
      />
      {showFooter !== false ? <Footer /> : null}
    </div>
  );
};

export default PageBuilder;
